import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Power Snatch 8-8-8 to an 8RM`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`2-Overhead Squats (95/65)`}</p>
    <p>{`2-K2E’s`}</p>
    <p>{`4-OHS`}</p>
    <p>{`4-K2E’s`}</p>
    <p>{`6-OHS`}</p>
    <p>{`6-K2E’s`}</p>
    <p>{`Etc.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open Workout 18.4 will be Saturday’s WOD.  Judging will
take place during the 9:00 & 10:00am classes as well as from
11:00-12:00.  Sunday is the next Rest Day so there will also be a
possibility of being judged during the 12:30 class that day.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      